<template>
  <div>
    <div class="flex">
      <h2 class="text-primary md:font-normal font-sans md:text-mid text-h5 leading-none md:italic md:font-serif md:tracking-tiny mb-9.5 uppercase text-center md:text-left md:normal-case font-bold tracking-lg">
        {{ $t('Earnings Report') }}
      </h2>
      <select class="ml-auto w-1/5 pl-2 inline-block mb-5"
              v-model="selectedBrand"
      >
        <option v-for="(account, idx) in getDetailedEarnings" :key="idx" :value="idx">{{ account.name }}</option>
      </select>
    </div>
    <div class="flex">
      <button class="earningsSelectButton mr-2 px-4 py-1" :class="{active : !selectedEarningsType}" @click="selectedEarningsType = 0">All Earnings</button>
      <button class="earningsSelectButton mr-2 px-4 py-1" :class="{active : selectedEarningsType}" @click="selectedEarningsType = 1">Expiring Soon</button>
      <span class="ml-10 mr-3 mt-1" v-if="selectedEarningsType">in</span>
      <select class="mb-2" v-model="expireLimit" v-if="selectedEarningsType">
        <option v-for="exLim in expireLimits" :key="exLim" :value="exLim">{{ exLim }}</option>
      </select>
      <span class="ml-3 mt-1" v-if="selectedEarningsType">days</span>
      <select class="ml-auto mb-2 w-2/12 md:w-1/12" v-model="pageSize">
        <option v-for="size in pageSizes" :key="size" :value="size">{{ size }}</option>
      </select>
    </div>
    <div class="" v-if="getDetailedEarnings.length">
      <div class="lg:px-8.5 lg:pt-xl lg:pb-xl bg-white mb-5 p-md">
        <div>
          {{ currentDetailedEarnings.name }}
        </div>
        <table width="100%">
          <thead>
          <tr>
            <th @click="sort('external_order_id')"
                class="font-serif text-base italic font-normal lg:leading-h1 border-b border-grey-14 border-solid text-left leading-none pb-5 lg:pb-0">{{ $t('Order ID') }}</th>
            <th
              @click="sort('amount')"
              class="font-serif text-base italic font-normal lg:leading-h1 border-b border-grey-14 border-solid text-left leading-none pb-5 lg:pb-0">{{ $t('Points Earned') }}</th>
            <th
              @click="sort('created_at')"
              class="font-serif text-base italic font-normal lg:leading-h1 border-b border-grey-14 border-solid text-left leading-none pb-5 lg:pb-0">{{ $t('Date') }}</th>
            <th
              @click="sort('is_redeemed')"
              class="font-serif text-base italic font-normal lg:leading-h1 border-b border-grey-14 border-solid text-left leading-none pb-5 lg:pb-0">{{ $t('Redeemed') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in paginatedEarnings" :key="item.id">
            <td class="border-b border-grey-14 border-solid py-6 leading-h6 text-h4 lg:text-base">
              {{ item.external_order_id }}
            </td>
            <td class="border-b border-grey-14 border-solid py-6 leading-h6 text-h4 lg:text-base">
              {{ (item.amount/100).toFixed(2) }}
            </td>
            <td class="border-b border-grey-14 border-solid py-6 leading-h6 text-h4 lg:text-base">
              {{ item.created_at | moment('MMMM Do YYYY, h:mm:ss a') }}
            </td>
            <td class="border-b border-grey-14 border-solid py-6 leading-h6 text-h4 lg:text-base">
              {{ item.is_redeemed ? 'Yes' : 'No' }}
            </td>
          </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="4" class="text-center mt-3">
                <button class="bg-black text-white px-4 py-1 mr-4 mt-4" @click="decrementPage">Prev</button>
                <button class="bg-black text-white px-4 py-1 mr-4 m4-" @click="incrementPage">Next</button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

    </div>
    <div v-else>
      <span>You haven't earned any points yet :(</span>
    </div>
  </div>
</template>

<script>
import moment from 'theme/filters/moment'
import config from 'config'

export default {
  name: 'EarningDetail',
  filters: {
    moment
  },
  props: {
    dashboard: {
      type: Object,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      currentSort: 'created_at',
      currentSortDir: 'asc',
      page: 0,
      pageSize: 50,
      selectedBrand: 0,
      selectedEarningsType: 0,
      expireLimit: 7, // show points expiring in this many days or less
      expireLimits: (config.rewards || {}).expiration_limits || [7, 30, 90],
      pageSizes: (config.rewards || {}).page_sizes || [10, 25, 50, 100]
    }
  },
  watch: {
    pageSize () {
      // reset page when changing pageSize, otherwise we might wind up on a blank page
      // i.e. current page is 4 because pageSize is 10, but switching pageSize to 50
      // leaves us with only 1 page, so now the screen is blank
      this.page = 0
    }
  },
  methods: {
    decrementPage () {
      if (this.page > 0) this.page -= 1
    },
    incrementPage () {
      if (this.page < Math.floor(this.filteredEarnings.length/this.pageSize)) this.page += 1
    },
    sortedDetailedEarnings (earnings) {
      return earnings.sort((a, b) => {
        let modifier = 1

        if (this.currentSortDir === 'asc') modifier = -1
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier
        if (a[this.currentSort] > b[this.currentSort]) return modifier
        return 0
      })
    },
    sort (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      }
      this.currentSort = s
    },
    paginate (array) {
      let filteredEarnings = this.selectedEarningsType ? this.getExpiringEarnings(array) : array
      return this.sortedDetailedEarnings(filteredEarnings).slice(this.indexStart, this.indexEnd)
    },
    parseCreatedDate (dateString) {
      try {
        return new Date(dateString)
      } catch (e) {
        console.error('Error parsing date string, using fallback:', e)
      }

      let cTime = dateString.split(' ')

      cTime = [...cTime[0].split('-'), ...cTime[1].split(':')]

      let parsedTime = new Date()

      parsedTime.setFullYear(cTime[0])
      parsedTime.setMonth(Number(cTime[1]) - 1)
      parsedTime.setDate(cTime[2])
      parsedTime.setHours(cTime[3])
      parsedTime.setMinutes(cTime[4])
      parsedTime.setSeconds(cTime[5])
      return parsedTime
    },
    getExpiringEarnings (array) {
      // assumes created_at time format: 'YYYY-MM-DD HH:MM:SS'
      return array.filter(el => {
        let expireTime = this.parseCreatedDate(el.created_at)

        expireTime.setFullYear((expireTime.getFullYear()) + 1)

        let expireLimit = this.expireLimit * 3600 * 24 * 1000 + Date.now() // expiring to the nearest second is good enough
        return (expireTime > Date.now() && expireTime < expireLimit && el.is_redeemed === false)
      })
    }
  },
  computed: {
    currentDetailedEarnings () {
      return this.getDetailedEarnings[this.selectedBrand]
    },
    filteredEarnings () {
      let currentEarnings = this.getDetailedEarnings[this.selectedBrand].earned

      if (this.selectedEarningsType === 1) {
        return this.getExpiringEarnings(currentEarnings)
      } else {
        return currentEarnings
      }
    },
    paginatedEarnings () {
      return this.sortedDetailedEarnings(this.filteredEarnings).slice(this.indexStart, this.indexEnd)
    },
    getDetailedEarnings () {
      return ((this.dashboard || {}).accounts || []).filter(account => account.active !== false)
    },
    indexStart () {
      return this.page * this.pageSize
    },
    indexEnd () {
      return (this.page * this.pageSize) + this.pageSize
    }
  }
}
</script>

<style lang="scss" scoped>
  .earningsSelectButton {
    color: #FFF;
    background-color: #666;
    &.active {
      background-color: #000;
    }
  }
  .earningsSelectButton:focus {
    outline: none;
  }
  .earningsSelectButton:first-of-type {
    border-right-width: 0px;
  }
  .profit-select {
    &::v-deep {
      select {
        @apply border-grey-21 bg-grey-27;
        @screen lg {
          @apply bg-white;
          width: 216px;
        }
      }
      .icon {
        width: 8px;
      }
    }
  }
</style>
