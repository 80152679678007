<template>
  <div class="rewards">
    <h2
      class="text-primary md:font-normal font-sans md:text-mid text-h5 leading-none md:italic md:font-serif md:tracking-tiny mb-9.5 uppercase text-center md:text-left md:normal-case font-bold tracking-lg">
      {{ $t('Rewards') }}
    </h2>
<!--    <account-rewards/>-->
<!--    <rewards-level/>-->
<!--    <account-next-level v-if="nextTierExists"/>-->
    <!--    <earn-more />-->

    <div class="flex flex-wrap justify-between items-stretch">
      <div v-if="!isBlackDiamond" class="main-reward-info w-full flex bg-black rounded-20px p-7 text-white flex-wrap border-4 border-primary2" :style="`border-color: #${currentTierDetails.hex_color || 'inherit'}`">
        <div class="w-1/2">
          <div class="flex items-center" v-if="currentTier !== baseTier">
            <div class="w-1/6">
              <reward-icon class="mr-3 block" :icon="currentTier"></reward-icon>
            </div>
            <div class="account__grade w-2/3 uppercase">
              <div class="text-base font-serif">Current Level</div>
              <p class="account__head text-mid" :style="`color: #${currentTierDetails.hex_color || 'inherit'}`">
                {{ currentTier }}
              </p>
            </div>
          </div>
          <div class="flex justify-center align-center" v-else>
            <p class="text-center text-white pt-10 pb-6">
              How to qualify for even more benefits:<br>
              <router-link :to="localizedRoute('/rewards-program/reward-levels')" class="underline">Learn about our
                reward program
              </router-link>
            </p>
          </div>
        </div>
        <div class="w-1/2 mb-10 lg:mb-0 flex flex-col text-right">
          <div class="flex">
            <div class="w-1/2"></div>
            <div class="w-1/2 text-center">
              <span
                :class="`bg-blue text-white text-md font-medium me-2 px-10 py-0.5 rounded-full w-full block points-bg--${currentTier}`">
                {{ getRcoLoyalty.balance }}
              </span>
              <span class="text-md">Loyalty Points</span>
            </div>
          </div>
          <div class="mt-auto">
          </div>
        </div>
        <div class="w-full" v-if="currentTier !== baseTier">
          <div class="w-full bg-gray-200 rounded-full h-2.5 bg-grey-1 relative my-4">
            <div class="bg-blue h-2.5 rounded-full text-center" :style="`width: ${levelToMaintainTierPercentage}%; background-color: #${currentTierDetails.hex_color} `">
              &nbsp;
            </div>
            <span class="block absolute text-center top-0 left-0 w-full text-white">{{ levelToMaintainTier | price }} left to maintain tier</span>
          </div>
        </div>
        <div class="w-1/2 text-center flex items-center justify-center flex-wrap">
          <div>
            <span class="block w-full font-serif">Current Quarter Spend</span>
            <span class="block w-full text-big mt-5">{{ currentQtrSpend | price }}</span>
          </div>
        </div>
        <div class="w-1/2 mt-10" v-if="currentTier !== baseTier">
          <div>
            <span class="text-big pr-2">{{ currentTierPercentage }}%</span>
            R+Co Reward Earning *
          </div>
          <div class="text-base">
            <span class="block text-left">*Earn points on eligible wholesale purchases. The more<br>you spend, the more points you earn to redeem for<br>products!</span>
            <span class="block text-center">
              <router-link :to="localizedRoute('/rewards-program/reward-levels')"
                           class="underline">Reward Tier Details</router-link>
            </span>
          </div>

        </div>
      </div>

      <div v-if="isBlackDiamond" class="main-reward-info w-full flex bg-black rounded-20px p-7 text-white flex-wrap border-4 border-primary2 relative">
        <div class="w-1/4 text-center absolute right-0 top-0 p-3">
              <span
                :class="`bg-blue text-white text-md font-medium me-2 px-10 py-0.5 rounded-full w-full block points-bg--black-diamond`">
                {{ getRcoLoyalty.balance }}
              </span>
          <span class="text-md">Loyalty Points</span>
        </div>
        <div class="w-full text-center">
          <div class="icon-placement">
            <reward-icon class="my-3 block w-1/4 m-auto" icon="black-diamond"></reward-icon>
          </div>
        </div>

        <div class="w-1/2 mt-10 m-auto text-center" v-if="currentTier !== baseTier">
          <div>
            <span class="text-big pr-2">{{ currentTierPercentage }}%</span>
            R+Co Reward Earning *
          </div>
          <div class="text-base">
            <span class="block text-center">
              <router-link :to="localizedRoute('/rewards-program/reward-levels')"
                           class="underline">Reward Tier Details</router-link>
            </span>
          </div>

        </div>
      </div>

      <div class="previous-quarter w-1/2 mb-3 mt-10" v-if="!isBlackDiamond && !isLockedTier">
        <div class=" h-full bg-black text-white border border-dark-1 rounded-20px p-10 text-center flex items-center justify-center mr-1 aspect-square">
          <div class="italic text-mid leading-h4 font-serif tracking-tiny">
            <div>{{ $t('Previous Quarter') }}</div>
            <div class="tier my-5 text-big" :style="`color: #${getPreviousTier.hex_color || 'inherit'}`">
              {{ (getPreviousTier.name || '').replace('R+Co', '') }}
            </div>
            <div class="detail">
              <span class="block">{{ $t('Avg. Monthly Spend') }}</span>
              <span class="block text-big mt-5">{{ previousTierSpend | price }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="next-rank w-1/2 mb-3 mt-10" v-if="!isBlackDiamond && !isLockedTier">
        <div class=" h-full bg-black text-white border border-dark-1 rounded-20px p-10 text-center flex items-center justify-center ml-1 aspect-square">
          <div class="italic text-mid leading-h4 font-serif tracking-tiny" v-if="!isLastRank">
            <div>{{ $t('Next Rank') }}</div>
            <div class="tier my-5 text-big" :style="`color: #${getNextTier.hex_color || 'inherit'}`">
              {{ ((getNextTier.name || '').replace('R+Co', ''))  }}
            </div>
            <div class="detail">
              <span class="block">{{ $t('Amount remaining to reach rank') }}</span>
              <span class="block">{{ nextTierSpend | price }}</span>
            </div>
          </div>
          <div v-else>
            <img :src="`/assets/2025/${currentTier}.png`" alt="Max Rank Reached" class="w-1/4 m-auto mb-5">
            <p class="font-serif text-big">Max Rank Reached</p>
          </div>
        </div>
      </div>
    </div>

    <earning-detail class="mt-10" :dashboard="dashboardInfo"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
// import AccountRewards from 'theme/components/theme/blocks/Account/AccountRewards'
// import AccountNextLevel from 'theme/components/theme/blocks/Account/AccountNextLevel'
// import RewardsLevel from 'theme/components/theme/blocks/Account/RewardsLevel'
import EarnedPoints from 'theme/mixins/blocks/EarnedPoints'
import {localizedRoute} from '@vue-storefront/core/lib/multistore'
import EarningDetail from 'theme/components/theme/blocks/Account/EarningDetail'
import RewardIcon from 'theme/components/core/blocks/Rewards/RewardIcon'
// import EarnMore from 'theme/components/theme/blocks/Account/EarnMore'

export default {
  name: 'Rewards',
  components: {
    RewardIcon,
    EarningDetail
    // AccountRewards,
    // AccountNextLevel,
    // RewardsLevel
    // EarnMore
  },
  mixins: [
    EarnedPoints
  ],
  data () {
    return {
      rewardPoints: {},
      tierOrder: [
        'gold',
        'pearl',
        'sapphire',
        'emerald',
        'ruby',
        'diamond'
      ],
      baseTier: 'gold'
    }
  },
  methods: {
    localizedRoute
  },
  computed: {
    ...mapGetters({
      dashboardInfo: 'loyalty/getDashboard',
      customerAccounts: 'loyalty/getCustomerAccounts'
    }),
    isLastRank () {
      return this.currentTier === this.tierOrder[this.tierOrder.length - 1]
    },
    levelToMaintainTier () {
      let level = this.currentTierMinSpending - this.currentQtrSpend

      if (level <= 0) {
        level = 0
      }

      return level
    },
    levelToMaintainTierPercentage () {
      if (this.currentQtrSpend >= this.currentTierMinSpending) {
        return 100
      }

      if (this.currentQtrSpend <= 0) {
        return 0
      }

      return this.currentQtrSpend / this.currentTierMinSpending * 100 || 0
    },
    getNextTier () {
      const currentTierIndex = this.tierOrder.indexOf(this.currentTier)

      if (currentTierIndex === -1) {
        return {}
      }

      const next = this.tierOrder[currentTierIndex + 1] || this.tierOrder[currentTierIndex]
      return (((this.dashboardInfo || {}).tier_table || {}).rco || []).find(x => x.common_name === next) || {}
    },
    getPreviousTier () {
      if (!(this.dashboardInfo || {}).previous_tier) {
        return {}
      }

      return (((this.dashboardInfo || {}).tier_table || {}).rco || []).find(x => x.common_name === (this.dashboardInfo || {}).previous_tier) || {}
    },
    currentTierPercentage () {
      return this.currentTierDetails.earning_percentage || 0
    },
    currentTierMinSpending () {
      return this.currentTierDetails.min_period_spend || 0
    },
    currentTier () {
      return this.dashboardInfo ? this.dashboardInfo.current_tier : ''
    },
    isBlackDiamond () {
      return (this.currentTier || '').includes('black_diamond')
    },
    isLockedTier () {
      return (this.dashboardInfo || {}).locked_tier
    },
    currentTierDetails () {
      return (((this.dashboardInfo || {}).tier_table || {}).rco || []).find(x => x.common_name === this.currentTier) || {}
    },
    currentQtrSpend () {
      return this.dashboardInfo ? this.dashboardInfo.period_spend : 0
    },
    nextTierSpend () {
      let spend = ((this.getNextTier || {}).min_period_spend || 0) - this.currentQtrSpend;

      return spend <= 0 ? 0 : spend
    },
    previousTierSpend () {
      return this.dashboardInfo ? this.dashboardInfo.last_qtr_avg_spend : 0
    },
    getRcoLoyalty () {
      return this.rewardPoints['rco'] || {}
    }
  },
  async beforeMount () {
    this.rewardPoints = await this.getRewards()
  }
}
</script>

<style lang="scss" scoped>
.main-reward-info {
  .points-bg {
    background-size: cover;

    &--gold {
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url('/assets/gold.jpg');
    }

    &--sapphire {
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url('/assets/sapphire.jpg');
    }

    &--pearl {
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url('/assets/pearl.jpg');
    }

    &--emerald {
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url('/assets/emerald.jpg');
    }

    &--ruby {
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url('/assets/ruby.jpg');
    }

    &--diamond {
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url('/assets/diamond.jpg');
    }

    &--black-diamond {
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url('/assets/black-diamond.png');
    }
  }
}
</style>
